import React from "react";
import { navigate } from "gatsby";
import page from "..";

export function Home() {
  if (typeof window !== 'undefined') navigate('https://frenchsignaturebath.com/');
  return null;
}

export default page(Home);
